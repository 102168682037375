<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <div class="form-row justify-content-between">
          <div class="col-md-2">
            <v-select
              label="title"
              required
              v-model="perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </div>
          <div class="col-md-4">
            <input
              type="search"
              class="form-control"
              placeholder="Pesquisar..."
              v-model="filter"
            />
          </div>
        </div>
      </div>

      <b-table
        sticky-header
        :no-border-collapse="true"
        class="position-relative"
        :items="authors.data"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        empty-filtered-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        striped
        hover
      >
        <template #cell(created_at)="data">
          {{ data.item.created_at | datePT }}
        </template>
        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              @click="confirmDelete(data.item.uuid)"
            />

            <!-- <feather-icon
              icon="EditIcon"
              class="cursor-pointer cursor"
              size="16"
              @click="
                $router.push({
                  name: 'config-authors-edit',
                  params: { uuid: data.item.uuid },
                })
              "
            /> -->
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2 mt-2 paginate-area">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class=""> Foram encontrados {{ totalRows }} resultados </span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              :limit="10"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="my-1"
              @input="updatePage()"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      centered
      id="modal-create-author"
      title="Cadastrar Jornalista"
      hide-footer
    >
      <b-card-text>
        <form
          ref="formCreateUser"
          class="p-0"
          @submit.prevent="submitCreateUser"
        >
          <div class="form-row">
            <div class="col-md-8">
              <div class="form-group">
                <label for="">Nome completo</label>
                <input
                  type="text"
                  id="full_name"
                  v-model="author.full_name"
                  minlength="5"
                  name="full_name"
                  class="form-control"
                  :class="{ 'is-invalid': $v.author.full_name.$error }"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label for="">CPF</label>
                <input
                  v-mask="['###.###.###-##']"
                  type="tel"
                  v-model="author.cpf"
                  :class="{ 'is-invalid': $v.author.cpf.$error }"
                  name="cpf"
                  placeholder="000.000.000-00"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-md-8">
              <div class="form-group">
                <label for="">E-mail</label>
                <input
                  type="email"
                  id="full-name"
                  v-model="author.email"
                  class="form-control"
                  :class="{ 'is-invalid': $v.author.email.$error }"
                />
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label for="">Celular</label>
                <input
                  v-mask="['(##) #####-####']"
                  type="tel"
                  v-model="author.cellphone"
                  name="cellphone"
                  placeholder="(00) 00000-0000"
                  class="form-control"
                  :class="{ 'is-invalid': $v.author.cellphone.$error }"
                />
              </div>
            </div>
          </div>

          <div class="form-row mt-2">
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Senha</label>
                <input
                  type="text"
                  v-model="author.password"
                  name="password"
                  class="form-control"
                  :class="{ 'is-invalid': $v.author.password.$error }"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Confirmação de Senha</label>
                <input
                  type="text"
                  v-model="author.repeatPassword"
                  class="form-control"
                  :class="{ 'is-invalid': $v.author.repeatPassword.$error }"
                />
              </div>
            </div>
          </div>

          <div class="form-row justify-content-end mt-2 pb-0">
            <div class="col-md-3">
              <button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                class="btn btn-block btn-outline-secondary"
                block
                @click="$bvModal.hide('modal-create-author')"
              >
                Fechar
              </button>
            </div>
            <div class="col-md-4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mr-2"
                type="submit"
                block
                :disabled="submited"
              >
                <div v-if="submited">
                  <b-spinner small variant="light" /> Verificando...
                </div>
                <div v-else>Cadastrar</div>
              </b-button>
            </div>
          </div>
        </form>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  BSidebar,
  BForm,
  BFormGroup,
  BFormRow,
  BSpinner,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { mapState } from "vuex";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";

export default {
  components: {
    BCardText,
    BSidebar,
    BFormGroup,
    BFormInput,
    BSpinner,
    BCard,
    BRow,
    BForm,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BFormRow,
    vSelect,
  },
  directives: {
    Ripple,
  },
  validations: {
    author: {
      full_name: {
        required,
        minLength: minLength(5),
      },
      cpf: {
        required,
        minLength: minLength(11),
      },
      cellphone: {
        required,
        minLength: minLength(11),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      repeatPassword: {
        sameAsPassword: sameAs("password"),
      },
    },
  },
  data: () => ({
    submited: false,
    filter: null,
    filterOn: [],
    currentPage: 1,
    perPage: 100,
    totalRows: 0,
    rowsTable: 0,
    perPageOptions: [
      {
        title: "100",
        value: 100,
      },
      {
        title: "200",
        value: 200,
      },
    ],
    author: {
      cpf: "",
      full_name: "",
      email: "",
      cellphone: "",
      password: "",
      repeatPassword: "",
    },
    tableColumns: [
      {
        key: "full_name",
        label: "Nome completo",
        sortable: false,
        thStyle: "width: 300px",
      },
      {
        key: "email",
        label: "E-mail",
        sortable: false,
      },
      {
        key: "created_at",
        label: "Cadastrado",
        sortable: false,
        class: "text-center",
        thStyle: "width: 190px",
      },
      {
        key: "actions",
        label: "",
        thClass: "text-center td-actions",
        thStyle: "width: 80px",
      },
    ],
  }),
  mounted() {
    this.getData();
    this.$root.$on("open:modal-create-author", () => {
      this.textSubmit = "Cadastrar";
      this.titleModal = "Cadastrar Parceiro";
      this.$bvModal.show("modal-create-author");
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-author");
  },
  computed: {
    ...mapState("Author", ["authors", "loading", "deleted"]),
  },
  methods: {
    submitCreateUser() {
      this.$v.$touch();
      this.submited = true;
      if (!this.$v.$error) {
        this.$store
          .dispatch("Author/create", this.author)
          .then(() => {
            this.notify(
              "Jornalista cadastrado com sucesso!",
              "UserCheckIcon",
              "success",
              "O usuário receberá a senha por e-mail."
            );
            this.$bvModal.hide("modal-create-author");
            this.getData();
            this.clearForm();
            this.$v.$reset;
          })
          .catch((err) => {
            this.submited = false;
            if (err.response.data.email) {
              this.notifyDefault("error", "E-mail já cadastrado");
            } else {
              this.notifyDefault("error");
            }
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    async getData() {
      this.$store
        .dispatch("Author/all", {
          currentPage: this.currentPage,
          perPage: this.perPage,
        })
        .then(() => {
          this.rowsTable = this.authors.data.length;
          this.totalRows = this.authors.meta.total;
          this.currentPage = this.authors.meta.current_page;
        });
    },
    clearForm() {
      this.author = {
        cpf: "",
        full_name: "",
        email: "",
        cellphone: "",
      };
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Só será possível deletar o usuário caso não tenha nenhum cadastro vinculado.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Author/delete", { uuid: uuid }).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "O usuário foi deletado com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
